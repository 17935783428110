<template>
  <div class="has-nav-bar view-user-index">
    <van-nav-bar
      title="我"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <van-icon
          class="home-message-icon"
          class-prefix="iconfont"
          name="ico-mess"
          :badge="messageCount"
          @click="messageHandle"
        />
      </template>
    </van-nav-bar>
    <div class="user-index-container">
      <div class="user-index-info">
        <van-icon class-prefix="iconfont" name="yonghu" />
        <dl v-if="$store.state.login.userInfo">
          <dt>{{ $store.state.login.userInfo.nickName }}</dt>
          <dd>{{ $store.state.login.userInfo.mobile }}</dd>
        </dl>
      </div>
      <van-cell-group style="margin-bottom: 2vw">
        <van-cell
          :is-link="!isIdent"
          :to="{
            name: !isIdent ? 'userFaceComparison' : '',
            params: { paramsData }
          }"
        >
          <template #title>
            <span class="custom-title">人脸比对</span>
            <div class="user-authentication">
              <div
                v-if="isIdent"
                class="user-authentication-btn user-authentication-btn-no"
              >
                已认证
              </div>
              <div v-else class="user-authentication-btn">未认证</div>
            </div>
          </template>
        </van-cell>
        <van-cell is-link :to="{ name: 'userPasswordEdit' }">
          <template #title>
            <span class="custom-title">修改密码</span>
          </template>
        </van-cell>
        <van-cell is-link :to="{ name: 'userTodoList' }">
          <template #title>
            <span class="custom-title">待办事项</span>
          </template>
        </van-cell>
        <van-cell is-link :to="{ name: 'userAddressBooks' }">
          <template #title>
            <span class="custom-title">通讯录</span>
          </template>
        </van-cell>
        <van-cell is-link :to="{ name: 'userMessage' }">
          <template #title>
            <span class="custom-title">系统消息</span>
            <van-tag
              v-if="unreadCount > 0"
              type="danger"
              round
              class="common-badge"
              >{{ unreadCount }}</van-tag
            >
          </template>
        </van-cell>
        <van-cell is-link :to="{ name: 'userSetting' }">
          <template #title>
            <span class="custom-title">设置</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import {
  CellGroup,
  Cell,
  Tag,
  Row,
  Col,
  Button,
  NavBar,
  Dialog,
  Toast,
  Icon
} from "vant";
import authApi from "@/api/authorization";
import { mapActions, mapState } from "vuex";
export default {
  name: "UserIndex",
  data() {
    return {
      isIdent: false,
      paramsData: ""
    };
  },
  computed: {
    ...mapState({
      unreadCount: state => state.message.unreadCount,
      userInfo: state => state.login.userInfo
    }),
    messageCount() {
      return this.unreadCount > 99 ? "99+" : this.unreadCount;
    }
  },
  methods: {
    async getProjectList() {
      let data = await authApi.getUserFaceStatus(this.userInfo.id);
      // let data = {
      //   certificationStatus: 20
      // };
      this.paramsData = data;
      if (data && data.certificationStatus == 20) {
        this.isIdent = true;
      } else {
        this.isIdent = false;
      }
    },
    ...mapActions(["logout", "clearToken"]),
    onClickLeft() {
      this.$router.back();
    },
    logoutHandle() {
      Dialog.confirm({
        message: "确认要退出当前用户吗？"
      }).then(() => {
        this.logout();
      });
    },
    logoutH5Handle() {
      this.clearToken(false);
    },
    messageHandle() {
      this.$router.push({ path: "/user/message" });
    }
  },
  created() {
    this.getProjectList();
  }
};
</script>
<style lang="scss">
.common-badge,
.van-info {
  background-color: $--badge-background-color;
  font-size: $--badge-font-size;
}
.common-badge {
  font-size: 10px;
  vertical-align: middle;
}
.view-user-index {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .van-nav-bar {
    background: #fff;
    .van-icon,
    .home-message-icon {
      color: $--color-primary;
      opacity: 0.9;
      font-size: 24px;
      position: relative;
      height: auto;
      line-height: 1;
      .van-info {
        background-color: #fd687d;
        right: 2px;
        top: 1px;
      }
    }
  }
  .user-index-container {
    margin: 0 52px;
    height: 100%;
    position: relative;
    .user-authentication {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .user-authentication-btn {
        width: 42px;
        height: 18px;
        background: rgba(233, 118, 23, 0.1);
        border-radius: 2px;
        border: 1px solid #e97617;
        line-height: 18px;
        text-align: center;
        font-size: 10px;
        color: #e97617;
        box-sizing: border-box;
      }
      .user-authentication-btn-no {
        background: rgba(125, 178, 39, 0.1);
        border: 1px solid #7db227;
        font-size: 10px;
        color: #7db227;
      }
    }
  }
  .user-index-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
    margin-bottom: 16px;
    line-height: 1;
    > i {
      color: #ddd;
      margin-right: 20px;
      font-size: 52px;
    }
    dt {
      margin-bottom: 8px;
      font-size: 17px;
      color: #0b0817;
    }
    dd {
      color: #adadbd;
      font-size: 15px;
    }
  }
  .van-cell-group {
    .van-cell {
      padding: 13px 0;
      color: #1b1b4e;
      &.van-cell:not(:last-child)::after {
        left: 0;
      }
      .van-cell__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
      }
      > .van-icon {
        color: #ebebeb;
      }
    }
    .van-tag {
      margin-left: 10px;
      background-color: #fd687d;
    }
  }
  .user-index-logout-btn {
    margin-top: 40px;
    background-color: #fd687d;
    border-color: #fd687d;
  }
}
</style>
